:root {
  --main-color: #006174;
  --main-color-opacity: #00617477;
  --second-color: #ffcc00;
  --third-color: rgb(8, 133, 153);
  --reserve-color: #073d6a;
}

.anima {
  height: 50vh;
  color: #fff;
  background: linear-gradient(65deg, #204eab, #45acf0, #03c758, #1f4484, #1187db);
  background-size: 200% 200%;
  }

  @-moz-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }
  
  @keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }

  @-webkit-keyframes Gradient {
    0% {
    background-position: 0% 50%
    }
  50% {
  background-position: 100% 50%
  }
  100% {
  background-position: 0% 50%
  }
  }

  .stamp_m {
    position: relative;
    display: inline-flex;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    color: red;
    padding: 15px;
    font-weight: 900;
    font-size: 18px;
    background-color: white;
    box-shadow: inset 0px 0px 0px 6px red, inset 0px 0px 0px 9px white,
      inset 0px 0px 0px 12px red;
    transform: rotate(-30deg);
    align-items: center;
    justify-content: center;
  }
  
  .stamp_m::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: url("/public/assets/img/noise.jpg");
  
    mix-blend-mode: lighten;
  }

.logo {
  width: 50px;
  margin: 16px;
  margin-top: 39px;
  margin-bottom: 136px;
}
.site-layout-sub-header-background {
  background: #fff;
}
.ant-card-body {
  background-color: none !important;
}

.ant-layout-header {
  line-height: 20px;
}
.ant-layout-sider {
  flex: 0 0 240px !important;
  width: 240px !important;
}
.ant-menu > .ant-menu-item {
  margin: 0 !important;
  height: 50px;
  border: 1px solid #3b8098;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}
.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-item-selected {
  background-color: #185266 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.own-input-mask {
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-top-color: rgb(217, 217, 217);
  border-right-color: rgb(217, 217, 217);
  border-right-width: 1px;
  border-bottom-color: rgb(217, 217, 217);
  border-left-color: rgb(217, 217, 217);
  border-radius: 2px;
  transition: all 0.3s;
}

.own-input-mask:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}

.own-input-mask:focus {
  border-color: #57a8e9;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
  box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
}
.ant-table-body {
  overflow-y: auto !important;
}

.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 80px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

.site-layout-background {
  padding: 0px !important;
}

.ant-drawer-body {
  background-color: #f4f7f9 !important;
}

*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-thumb {
  background-color: #aaa7a6;
  border-radius: 9999px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #238793;
}
.ant-table-pagination.ant-pagination {
  margin: 30px !important;
}

.ant-table-cell {
  /*color: #3b8098 !important;*/
}

.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background: #006d7d;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}

.ant-picker {
  width: 130px !important;
  border-radius: 6px !important;
  background-color: #238793 !important;
  color: #fff !important;
}

.ant-picker-input {
  color: white !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

/* .active {
  background-color: #185468 !important;
  border-right: 4px solid blue;
} */
@media print {
  body {
    display: flex;
    justify-content: center;
  }
  .ant-drawer-body {
    background-color: #fff !important;
  }
  .anticon-close {
    visibility: hidden;
  }
  #PrintButton {
    visibility: hidden;
  }
  #app-title {
    visibility: hidden;
  }
  #app-content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 !important;
  }
  #app-prev {
    visibility: hidden;
  }
  #app-text {
    margin: 0 !important;
    width: 100% !important;
  }
  #app-text p {
    color: #000;
    font-size: 14px !important;
  }
  #application-logo {
    filter: grayscale(100%);
  }
  .bg-\[\#F4F7F9\] {
    background-color: #fff !important;
  }
  .drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 0 0 #fff) drop-shadow(0 0 0 #fff) !important;
    filter: none;
  }

  .sec_0 {
    display: none;
  }
  .sec_1 {
    display: none !important;
  }
  .sec_2 {
    display: none !important;
  }
  .sec_3 {
    display: none !important;
  }
}

.ant-table-row {
  cursor: pointer;
}

.bg-\[\#006174\] {
  background: linear-gradient(90deg, black, var(--main-color));
}

.ant-picker {
  background-color: #fff !important;
}

.ant-radio-button-wrapper-checked:not(
    [class*=" ant-radio-button-wrapper-disabled"]
  ).ant-radio-button-wrapper:first-child {
  border-right-color: #5752ee;
}

.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #5752ee;
  border-color: #5752ee;
}

.ant-radio-group-solid:hover .ant-radio-button-wrapper-checked:hover {
  background: #663aec;
  border-color: #663aec;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
